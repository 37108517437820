<template>
  <section id="agendador">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Desativar datas
              </h4>
            </b-col>

            <b-col class="text-right">
              <b-button
                class="mb-2"
                @click="newDisabledDate"
              >
                Nova data desativada
              </b-button>
            </b-col>
          </b-row>

          <b-table
            :busy="isLoading || isRemoving"
            fixed
            :items="results"
            :fields="resultFields"
            sort-icon-left
            no-local-sorting
          >

            <template #head(date)>
              <div class="text-nowrap text-center">
                Data
              </div>
            </template>

            <template #head(interval)>
              <div class="text-nowrap text-center">
                Intervalo
              </div>
            </template>

            <template #head(relation)>
              <div class="text-nowrap text-center">
                Relação
              </div>
            </template>

            <template #head(actions)>
              <div class="text-nowrap text-center">
                Ações
              </div>
            </template>

            <template #cell(date)="row">
              {{ row.item.date | date }}
            </template>

            <template #cell(name)="row">
              <span>{{ row.item.name }}</span><br>
              <span>{{ row.item.email }}</span>
            </template>

            <template #cell(interval)="row">
              <div v-if="row.item.start_at !== null">
                Das {{ row.item.start_at }} até {{ row.item.end_at }}
              </div>
              <div v-else>
                O dia inteiro
              </div>
            </template>

            <template #cell(relation)="row">
              <div v-if="row.item.table_name === 'hosts'">
                <b-badge
                  variant="warning"
                  class="badge-glow"
                >
                  Host
                </b-badge> {{ row.item.host.name }}
              </div>

              <div v-if="row.item.table_name === 'events'">
                <b-badge
                  variant="info"
                  class="badge-glow"
                >
                  Evento
                </b-badge> {{ row.item.event.user.name }} - {{ row.item.event.name }}
              </div>

              <div v-if="row.item.table_name === 'users'">
                <b-badge
                  variant="success"
                  class="badge-glow"
                >
                  Usuário
                </b-badge> {{ row.item.user.name }}
              </div>

            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap text-center">
                <b-button
                  variant="flat-primary"
                  title="Remover"
                  :disabled="isLoading || isRemoving"
                  @click="removeDisabledDate(row.item)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="modalNewDisabledDate"
      title="Desativa nova Data"
      size="md"
      :ok-disabled="isSubmiting"
      :cancel-disabled="isSubmiting"
      @ok="handleNewDisabledDate"
    >
      <b-card>
        <validation-observer>
          <b-form-group
            label="Data"
            label-for="date"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <flat-pickr
                id="date"
                ref="datePicker"
                v-model="disabledDate.date"
                class="form-control"
                :config="{
                  inline:false,
                  allowInput:false,
                  position: 'auto center',
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                  time_24hr: true,
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Dia inteiro"
            label-for="all_day"
          >
            <b-form-checkbox
              id="all_day"
              v-model="disabledDate.all_day"
              :disabled="isSubmiting"
            />
          </b-form-group>

          <b-form-group
            v-if="!disabledDate.all_day"
            label="Inicio em (formato HORA:MINUTO. ex: 14:00)"
            label-for="start_at"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
            >
              <cleave
                id="start_at"
                v-model="disabledDate.start_at"
                class="form-control"
                :options="maskTime"
                :raw="false"
                :disabled="isSubmiting"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="!disabledDate.all_day"
            label="Até (formato HORA:MINUTO. ex: 14:15)"
            label-for="end_at"
          >
            <validation-provider
              #default="{ errors }"
              rules=""
            >
              <cleave
                id="end_at"
                v-model="disabledDate.end_at"
                class="form-control"
                :options="maskTime"
                :raw="false"
                :disabled="isSubmiting"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Relação"
            label-for="relation"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-spinner v-if="isLoadingRelations">
                Carregando
              </b-spinner>

              <b-form-select
                v-else
                id="table_name"
                v-model="disabledDate.table_name"
                :options="relationTypes"
                :disabled="isSubmiting"
                @change="disabledDate.table_id = null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="disabledDate.table_name !== '' && !isLoadingRelations"
            :label="relationSelected"
            label-for="table_id"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-select
                id="table_id"
                v-model="disabledDate.table_id"
                :options="relationsFiltered"
                :disabled="isSubmiting"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-card>
    </b-modal>

  </section>
</template>

<script>
import {
  BBreadcrumb, BButton, BCard,
  BCol, BFormGroup, BFormCheckbox, BFormSelect, BModal,
  BRow, BTable, BSpinner, BBadge,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import SchedulerService from '@/services/schedulerService'
import toasts from '@/mixins/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { maskTime } from '@/utils/masks'

export default {
  components: {
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormGroup,
    BModal,
    BCard,
    BButton,
    BTable,
    BBadge,
    BSpinner,
    BRow,
    BCol,
    BBreadcrumb,
    flatPickr,
    Cleave,
  },

  mixins: [toasts],

  props: {
    managerIdFromProps: {
      type: Number,
      default: null,
    },
    paymentDateFrom: {
      type: String,
      default: null,
    },
    paymentDateTo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Projetos',
          active: true,
        },
        {
          text: 'Agendador',
          active: true,
        },
        {
          text: 'Desativar Datas',
          active: true,
        },
      ],
      disabledDate: {
        table_name: '',
        table_id: '',
        date: null,
        start_at: null,
        end_at: null,
        all_day: true,
      },
      results: [],
      relations: [],
      relationTypes: [],
      resultFields: [
        { key: 'date', sortable: true },
        { key: 'interval', sortable: true },
        { key: 'relation', sortable: true },
        { key: 'actions', sortable: false, thStyle: 'width: 180px' },
      ],
      userData: null,
      isLoading: false,
      isLoadingRelations: false,
      isRemoving: false,
      isSubmiting: false,
      maskTime,
    }
  },

  computed: {
    relationSelected() {
      return this.relationTypes.find(f => f.value === this.disabledDate.table_name)?.text
    },

    relationsFiltered() {
      return this.relations.filter(f => f.table_name === this.disabledDate.table_name).map(m => ({
        value: m.table_id,
        text: m.text,
      }))
    },
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    this.getDisabledDates()
  },

  methods: {
    removeDisabledDate(disabledDate) {
      this.$swal({
        title: 'Remover Data Desativada?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isRemoving = true
          SchedulerService.deleteDisabledDate(disabledDate.id).then(response => {
            if (response.data.data.success) {
              this.toastSuccess('Sucesso!', 'Data removida!')
              this.getDisabledDates()
            } else {
              this.toastDanger('Erro!', 'Erro ao remover o data')
            }
          }).catch(() => {
            this.toastDanger('Erro!', 'Erro ao remover o data')
          }).finally(() => {
            this.isRemoving = false
          })
        }
      }).catch(() => {
        this.toastDanger('Erro!', 'Erro ao remover o data')
      })
    },

    handleNewDisabledDate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isSubmiting = true

      SchedulerService.postDisabledDate(this.disabledDate).then(response => {
        if (response.data.data.success) {
          this.$refs.modalNewDisabledDate.hide()
          this.getDisabledDates()
          this.toastSuccess('Sucesso!', 'Data desativada adicionada!')
        } else {
          this.toastDanger('Erro!', 'Aconteceu um erro ao tentar desativar esta data')
        }
      }).catch(error => {
        this.toastDanger('Erro!', this.getResponseErrorMessage(error.response.data))
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    newDisabledDate() {
      if (this.relations.length === 0) {
        this.isLoadingRelations = true
        SchedulerService.getDisabledDatesRelations().then(response => {
          if (response.data !== null) {
            this.relations = response.data.data.disabled_dates_relations
            this.relations.forEach(item => {
              if (this.relationTypes.filter(f => f.text === item.relation).length === 0) {
                this.relationTypes.push({
                  text: item.relation,
                  value: item.table_name,
                })
              }
            })
          }
        }).finally(() => {
          this.isLoadingRelations = false
        })
      }

      this.$refs.modalNewDisabledDate.show()
    },

    getDisabledDates() {
      this.isLoading = true
      SchedulerService.getDisabledDates().then(response => {
        if (response.data !== null) {
          this.results = response.data.data.disabled_dates
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
